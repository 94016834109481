import React, { useCallback, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'

import { getLogo, uploadLogo } from 'api/objectStorage'
import useToast from 'hooks/useToast'

import {
    DragAndDropButton,
    DragAndDropText,
    DragAndDropWrapper,
    DragDropFileContainer,
    LogoSection,
    ReplaceLogoButton,
    UpdateLogoSection,
    ViewLogoContainer,
} from './UploadLogo.styled'

interface DragDropFileProps {
    disabled: boolean
}

export default function UploadLogo(defaultProps: DragDropFileProps) {
    const { disabled } = defaultProps
    const { successToast, errorToast } = useToast()
    const [logo, setLogo] = useState<File | null>(null)
    const [canSubmitLogo, setCanSubmitLogo] = useState(false)
    const [logoUrl, setLogoUrl] = useState('')
    const [logoUploaded, setLogoUploaded] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getLogo()
            .then((res) => {
                setLogoUrl(res.data)
                setLogoUploaded(true)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    const onDrop = useCallback((acceptedFiles: any) => {
        acceptedFiles.forEach((file: any) => {
            const reader = new FileReader()

            reader.onload = () => {
                setLogoUploaded(false)
                setLogo(file)
            }

            reader.readAsDataURL(file)
        })
    }, [])

    const onDropRejected = useCallback((fileRejections: any) => {
        fileRejections.forEach((file: any) => {
            if (
                file.errors.some(
                    (error: any) => error.code === 'file-too-large',
                )
            ) {
                errorToast(
                    // eslint-disable-next-line max-len
                    'File is too large. Make sure the logo is smaller than 512kb.',
                )
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/png': ['.png'],
            'image/svg+xml': ['.svg'],
        },
        onDropRejected,
        maxSize: 512000,
    })

    useEffect(() => {
        if (logo) {
            setCanSubmitLogo(false)
            const acceptedFormats = ['image/png', 'image/svg+xml']

            if (!acceptedFormats.includes(logo.type)) {
                setLogoUrl('')

                return
            }

            if (logo.size / 1000 > 512) {
                setLogoUrl('')

                return
            }

            const reader = new FileReader()

            reader.onloadend = () => {
                setLogoUrl(reader.result as string)
                setCanSubmitLogo(true)
            }
            reader.readAsDataURL(logo)
        } else {
            setLogoUrl('')
            setCanSubmitLogo(false)
        }
    }, [logo])

    const handleSubmitLogo = () => {
        if (!logo) return

        uploadLogo(logo)
            .then(() => {
                successToast('Logo uploaded successfully')
                setCanSubmitLogo(false)
                setLogoUploaded(true)
            })
            .catch((err) => {
                if (err.response.status === 409) {
                    return errorToast('Logo already exists.')
                }
                if (err.response.status !== 500) {
                    return errorToast('Failed to save logo, please try again.')
                }

                return errorToast(
                    // eslint-disable-next-line max-len
                    'Unknown error. Please try again and contact Sharlic support if error persists.',
                )
            })
    }

    const renderUploadLogoSection = () => {
        return (
            <DragDropFileContainer {...getRootProps()}>
                <input {...getInputProps()} />
                <DragAndDropWrapper>
                    <div className="fa-thin fa-cloud-arrow-up icon" />
                    <DragAndDropText>
                        <span>Drag&Drop image here</span>
                        <p>or</p>
                        <DragAndDropButton>
                            Click to select image
                        </DragAndDropButton>
                    </DragAndDropText>
                </DragAndDropWrapper>
            </DragDropFileContainer>
        )
    }

    const renderLogoSection = () => {
        return (
            <LogoSection>
                {logoUploaded && <div className="fa-solid fa-cloud-check" />}
                <img src={logoUrl} alt="logo" />
            </LogoSection>
        )
    }

    const renderDisplayLogoSection = () => {
        const uploaded = logoUploaded ? 'Saved' : 'Save image'

        return (
            <ViewLogoContainer>
                {renderLogoSection()}
                <Button
                    disabled={disabled || !canSubmitLogo}
                    onClick={() => {
                        handleSubmitLogo()
                    }}
                >
                    {uploaded}
                </Button>
                <UpdateLogoSection {...getRootProps()}>
                    <ReplaceLogoButton>
                        <input {...getInputProps()} />
                        Select another image..
                    </ReplaceLogoButton>
                </UpdateLogoSection>
            </ViewLogoContainer>
        )
    }

    if (loading) {
        return <ViewLogoContainer />
    }

    return logoUrl ? renderDisplayLogoSection() : renderUploadLogoSection()
}
