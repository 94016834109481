import React, { useState } from 'react'

import { getArticles } from 'api/articles'
import AccordionItem from 'apps/shared/components/AccordionItem'
import { EditButton } from 'apps/shared/components/Buttons'
import { EmptyListIcon, BooleanIcon } from 'apps/shared/components/Icons'
import TextButton from 'apps/vendor/components/Buttons'
import { ArticleModal } from 'apps/vendor/components/Modals/'
import {
    AccordionBody,
    AccordionWrapper,
    BadgeRow,
    HeaderRow,
} from 'apps/vendor/pages/pages.styled'
import { localeNumber } from 'apps/vendor/utils'
import useUser from 'hooks/useUser'

import PageHeader from '../PageHeader'

export type ArticleType = {
    id: number
    external_id: string
    name: string
    description: string
    msrp: number | string
    currency: string
    available_for_partners: boolean
    vendor: number
}

export default function ArticlesPage(props: {
    updateArticles: (data: ArticleType[]) => void
    articles: ArticleType[]
}) {
    const { articles, updateArticles } = props
    const [showArticleModal, setShowArticleModal] = useState(false)

    const [selectedArticle, setSelectedArticle] = useState<ArticleType | null>(
        null,
    )
    const { user } = useUser()

    const updateArticlesList = () => {
        getArticles().then((response) => {
            updateArticles(response.data)
        })
    }

    const handleShowArticle = () => {
        setShowArticleModal(!showArticleModal)
    }

    const onArticleModalSubmit = () => {
        updateArticlesList()
        setShowArticleModal(false)
        setSelectedArticle(null)
    }

    const handleOpenEditModal = (article: ArticleType) => {
        setSelectedArticle(article)
        handleShowArticle()
    }

    const getAccordionHeaders = (article: ArticleType) => {
        return (
            <BadgeRow numberOfColumns={4} rightJustifiedColumns={[2]}>
                <TextButton
                    onClick={() => handleOpenEditModal(article)}
                    text={article.name}
                />
                <span>
                    {localeNumber(Number(article.msrp), {
                        locale: user?.locale,
                    })}
                </span>
                <span>{article.currency}</span>
                <span>{article.external_id}</span>
            </BadgeRow>
        )
    }

    const getAccordionBody = (article: ArticleType) => {
        return (
            <AccordionBody>
                <div>
                    <p>Description</p>
                    <p>{article.description || '-'}</p>
                </div>
                <div>
                    <p className="centered">Available for partners</p>
                    <p className="centered">
                        <BooleanIcon checked={article.available_for_partners} />
                    </p>
                </div>
                <EditButton
                    onClick={() => {
                        handleOpenEditModal(article)
                    }}
                />
            </AccordionBody>
        )
    }

    const renderTable = () => {
        if (!articles) return null

        if (articles.length === 0) {
            return <EmptyListIcon />
        }

        return (
            <>
                <HeaderRow numberOfColumns={4} rightJustifiedColumns={[2]}>
                    <span>Name</span>
                    <span>MSRP</span>
                    <span>Currency</span>
                    <span>Article number</span>
                </HeaderRow>
                <AccordionWrapper alwaysOpen>
                    {articles.map((article, index) => (
                        <AccordionItem
                            key={article.external_id}
                            eventKey={index.toString()}
                            header={getAccordionHeaders(article)}
                            body={getAccordionBody(article)}
                        />
                    ))}
                </AccordionWrapper>
            </>
        )
    }

    return (
        <div>
            <PageHeader onButtonClicked={() => handleShowArticle()} />
            {showArticleModal && (
                <ArticleModal
                    onClose={() => {
                        handleShowArticle()
                        setSelectedArticle(null)
                    }}
                    onSubmit={() => onArticleModalSubmit()}
                    preselectedArticle={selectedArticle}
                />
            )}
            {renderTable()}
        </div>
    )
}
