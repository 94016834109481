import React, { useEffect, useState } from 'react'

import { getUsers } from 'api/users'
import AccordionItem from 'apps/shared/components/AccordionItem'
import { BooleanIcon } from 'apps/shared/components/Icons'
import { NewVendorUserModal } from 'apps/vendor/components/Modals'
import { formatDate } from 'apps/vendor/utils'
import useUser from 'hooks/useUser'
import { User } from 'state/user/UserState'

import PageHeader from '../PageHeader'
import {
    AccordionBody,
    AccordionWrapper,
    BadgeRow,
    HeaderRow,
} from '../pages.styled'

export default function Users() {
    const [users, setUsers] = useState<User[] | null>(null)
    const [userObjectsList, setUserObjectsList] = useState<User[] | null>(null)
    const [showNewUserModal, setShowNewUserModal] = useState(false)
    const [rerender, setRerender] = useState(false)
    const currentUser = useUser().user

    useEffect(() => {
        getUsers().then((response) => {
            setUsers(response.data)
        })
    }, [rerender])

    useEffect(() => {
        if (!users) return

        setUserObjectsList([
            ...users.map((user) => {
                if (user.id === currentUser?.id) {
                    return {
                        ...user,
                        name: `${currentUser.first_name}
                            ${currentUser.last_name}`,
                    }
                }

                return {
                    ...user,
                    name:
                        (user.first_name || user.last_name) &&
                        `${user.first_name} ${user.last_name}`,
                }
            }),
        ])
    }, [users, currentUser])

    const handleShowUser = () => {
        setShowNewUserModal((current) => !current)
    }

    const updateUserList = (user: {
        id: number
        email: string
        is_vendor_admin: boolean
    }) => {
        if (!users) return

        setUsers([
            {
                ...user,
                first_name: '',
                last_name: '',
                name: '',
                user_type: 'vendor',
                phone_number: '',
                date_joined: '',
            },
            ...users,
        ])
        setShowNewUserModal(false)
        setRerender(!rerender)
    }

    const getUserBody = (user: User) => {
        const dateString = formatDate(
            new Date(user.date_joined),
            currentUser?.locale,
        )

        return (
            <AccordionBody>
                <div>
                    <p>Joined</p>
                    <p>{dateString}</p>
                </div>
            </AccordionBody>
        )
    }

    const renderTable = () => {
        if (!users || !userObjectsList) {
            return null
        }

        return (
            <>
                <HeaderRow numberOfColumns={4}>
                    <span>Name</span>
                    <span>Email</span>
                    <span>Phone Number</span>
                    <span className="centered">Admin</span>
                </HeaderRow>
                <AccordionWrapper alwaysOpen>
                    {userObjectsList.map((user, index) => {
                        return (
                            <AccordionItem
                                key={user.id}
                                eventKey={index.toString()}
                                header={
                                    <BadgeRow numberOfColumns={4}>
                                        <span>{user.name || '-'}</span>
                                        <span>{user.email}</span>
                                        <span>{user.phone_number || '-'}</span>
                                        <span className="centered">
                                            <BooleanIcon
                                                checked={user.is_vendor_admin}
                                            />
                                        </span>
                                    </BadgeRow>
                                }
                                body={getUserBody(user)}
                            />
                        )
                    })}
                </AccordionWrapper>
            </>
        )
    }

    return (
        <div>
            <PageHeader onButtonClicked={() => handleShowUser()} />
            {showNewUserModal && (
                <NewVendorUserModal
                    onClose={() => handleShowUser()}
                    updateUserList={updateUserList}
                />
            )}
            {renderTable()}
        </div>
    )
}
