import styled from 'styled-components'
import { primary, secondary } from 'utils/colors'

interface ColumnProps {
    numberOfColumns: number
}

export const TableWrapper = styled.div<ColumnProps>`
    display: flex;

    thead {
        font-size: 0.8rem;
        color: ${primary.gray};
    }

    > table {
        > * > tr {
            display: grid;
            grid-template-columns: ${({ numberOfColumns }) =>
                `repeat(${numberOfColumns}, 1fr)`};

            > td {
                .pdf-icon {
                    padding: 4px;
                    cursor: pointer;
                }
            }
        }
    }

    > div {
        align-self: center;
        padding-top: 20px;
        padding-left: 20px;
        font-size: 1.5rem;
    }

    .clickable-row {
        :hover {
            background-color: ${secondary.whisperWhite};
            cursor: pointer;
        }
    }

    .right-justified {
        display: grid;
        grid-template-columns: 2fr 1fr;
        text-align: right;
    }

    .centered {
        display: grid;
        grid-template-columns: 1fr;
        text-align: center;

        > * {
            max-width: 100%;
            place-self: center;
        }
    }
`
