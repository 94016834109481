import React, { useEffect, useRef, useState } from 'react'
import { Dropdown, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

import patchCurrentUser from 'api/users/patchCurrentUser'
import {
    EmailInput,
    PhoneInputField,
} from 'apps/shared/components/FormInputFields'
import { Modal } from 'apps/shared/components/Modals'
import { CancelSubmitFooter } from 'apps/shared/components/Modals/Footers'
import {
    ErrorContainer,
    ErrorMessage,
    FormLabel,
} from 'apps/shared/shared.styled'
import useToast from 'hooks/useToast'
import useUser from 'hooks/useUser'
import { loadUser } from 'state/user'
import isEmail from 'validator/lib/isEmail'

import { DropdownContainer, ModalWrapper } from '../Modals.styled'

type ProfileModalProps = {
    onClose: () => void
    onSubmit: () => void
}

export default function ProfileModal(props: ProfileModalProps) {
    const { onClose, onSubmit } = props
    const { user } = useUser()
    const dispatch = useDispatch()
    const { errorToast, successToast } = useToast()
    const [email, setEmail] = useState(user?.email || '')
    const [emailWarning, setEmailWarning] = useState('')
    const [firstName, setFirstName] = useState(user?.first_name || '')
    const [lastName, setLastName] = useState(user?.last_name || '')
    const [phoneNumber, setPhoneNumber] = useState(user?.phone_number || '')
    const [phoneNumberError, setPhoneNumberError] = useState('')
    const [locale, setLocale] = useState(user?.locale || '')
    const [canSubmit, setCanSubmit] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])

    useEffect(() => {
        if (user) {
            const formIsDirty =
                user.email !== email ||
                user.first_name !== firstName ||
                user.last_name !== lastName ||
                user.phone_number !== phoneNumber ||
                user.locale !== locale

            const isValidEmail = isEmail(email)
            const formIsValid = isValidEmail && !phoneNumberError

            const requiredFieldsSet =
                email !== '' && firstName !== '' && lastName !== ''

            setCanSubmit(requiredFieldsSet && formIsValid && formIsDirty)
        }
    }, [
        user,
        email,
        phoneNumber,
        phoneNumberError,
        firstName,
        lastName,
        locale,
    ])

    const handleSubmit = () => {
        if (!canSubmit) {
            return
        }

        const userData = {
            first_name: firstName,
            last_name: lastName,
            email,
            phone_number: phoneNumber,
            locale,
        }

        patchCurrentUser(userData)
            .then(() => {
                dispatch(loadUser())
                onSubmit()
                successToast('Changes saved')
            })
            .catch((err) => {
                if (err.response.status !== 500) {
                    return errorToast(
                        'Failed to save changes, please try again.',
                    )
                }

                return errorToast(
                    // eslint-disable-next-line max-len
                    'Unknown error. Please try again and contact Sharlic support if error persists.',
                )
            })
    }

    const handleSetEmail = (value: string) => {
        setEmail(value)
    }

    const validateEmail = () => {
        const isValid = isEmail(email)
        const warning = isValid ? '' : 'Invalid email format.'

        setEmailWarning(warning)
    }

    const renderEmailField = () => {
        return (
            <>
                <EmailInput
                    value={email || ''}
                    onChange={(value: string) => handleSetEmail(value)}
                    onBlur={() => {
                        validateEmail()
                    }}
                    displayIcon
                />
                {emailWarning && (
                    <ErrorContainer>
                        <ErrorMessage>{emailWarning}</ErrorMessage>
                    </ErrorContainer>
                )}
            </>
        )
    }

    const renderPhoneSection = () => {
        return (
            <>
                <FormLabel>Phone number</FormLabel>
                <PhoneInputField
                    {...{
                        phoneNumber,
                        onChange: (phoneInput, errorMessage) => {
                            setPhoneNumber(phoneInput)
                            setPhoneNumberError(errorMessage)
                        },
                    }}
                />
                {phoneNumberError && (
                    <ErrorContainer>
                        <ErrorMessage>{phoneNumberError}</ErrorMessage>
                    </ErrorContainer>
                )}
            </>
        )
    }

    const renderLocaleSection = () => {
        const localeOptions = [
            { value: 'en-US', label: 'English' },
            { value: 'sv-SE', label: 'Swedish' },
        ]

        const formattedLocale = localeOptions.find(
            (option) => option.value === locale,
        )

        return (
            <>
                <FormLabel>Locale</FormLabel>
                <DropdownContainer>
                    <Dropdown>
                        <Dropdown.Toggle
                            variant="outline-dark"
                            id="dropdown-basic"
                        >
                            {formattedLocale?.label || 'Select locale'}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {localeOptions.map((localeOption) => (
                                <Dropdown.Item
                                    key={localeOption.value}
                                    onClick={() => {
                                        setLocale(localeOption.value)
                                    }}
                                >
                                    {localeOption.label}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </DropdownContainer>
            </>
        )
    }

    return (
        <Modal
            onClose={onClose}
            title="Profile"
            body={
                <ModalWrapper>
                    <FormLabel>First Name</FormLabel>
                    <Form.Control
                        ref={inputRef}
                        type="text"
                        name="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        onBlur={(e) => setFirstName(e.target.value.trim())}
                    />
                    <FormLabel>Last Name</FormLabel>
                    <Form.Control
                        type="text"
                        name="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        onBlur={(e) => setLastName(e.target.value.trim())}
                    />
                    <FormLabel>Email</FormLabel>
                    {renderEmailField()}
                    {renderPhoneSection()}
                    {renderLocaleSection()}
                </ModalWrapper>
            }
            footer={
                <CancelSubmitFooter
                    onClose={onClose}
                    onSubmit={handleSubmit}
                    canSubmit={canSubmit}
                />
            }
        />
    )
}
