import styled from 'styled-components'
import { primary, secondary } from 'utils/colors'

export const DragDropFileContainer = styled.div`
    border: 2px dashed ${secondary.lightGray};
    height: 200px;
    width: 100%;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: border-color 0.3s;
    &:hover {
        border-color: ${primary.lightBlue};
    }

    > div {
        font-size: 2rem;
    }
`

export const DragAndDropWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${primary.lightGray};
    > div {
        font-size: 3rem;
    }
`

export const DragAndDropText = styled.span`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;

    > p {
        font-size: 1rem;
    }
`

export const DragAndDropButton = styled.div`
    margin-bottom: 16px;
    height: 40px;
    width: 200px;
    font-size: 1rem;
    color: ${primary.lightBlue};
    font-weight: bold;
    border: 1px solid ${primary.lightBlue};
    border-radius: 5px;
    text-align: center;
    align-content: center;
    &:hover {
        border-color: ${primary.blue};
        color: ${primary.blue};
    }
`

export const ViewLogoContainer = styled.div`
    display: grid;
    justify-items: center;
    grid-template-rows: auto 1fr;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-bottom: 20px;

    > button {
        width: 300px;
        margin-top: 16px;
        margin-bottom: 8px;
    }
`

export const UpdateLogoSection = styled.div`
    border: 1px dotted ${primary.lightBlue};
    align-items: center;
    width: 300px;
    border-radius: 5px;
    cursor: pointer;
    transition: border-color 0.3s;
    &:hover {
        border-color: ${primary.lightGray};
        background-color: ${secondary.lightGray};
    }
`

export const ReplaceLogoButton = styled.div`
    font-size: 14.4px;
    text-align: center;
    padding: 6px 8px;
    cursor: pointer;
    transition: border-color 0.3s;
    &:hover {
        border-color: ${primary.gray};
    }
`

export const LogoSection = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    > img {
        padding: 2px;
        pointer-events: none;
        max-width: 200px;
        max-height: 100%;
        height: auto;
        margin: 8px;
    }

    > div {
        position: absolute;
        top: -32px;
        left: -60px;
        font-size: 1.3rem;
        color: ${primary.green};
    }
`
