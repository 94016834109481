import React from 'react'

import useEnforceAriaHiddenFalse from 'hooks/useEnforceAriaHiddenFalse'

import { EditButtonWrapper } from './EditButton.styled'

export default function EditButton(props: { onClick: () => void }) {
    const { onClick } = props
    const buttonRef = useEnforceAriaHiddenFalse<HTMLDivElement>()

    return (
        <EditButtonWrapper
            ref={buttonRef}
            className="fa-solid fa-pen-to-square"
            onClick={(e) => {
                e.stopPropagation()
                onClick()
            }}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    e.stopPropagation()
                    onClick()
                }
            }}
            tabIndex={0}
            role="button"
            aria-label="Edit button"
        />
    )
}
