import React, { useEffect, useRef } from 'react'

type ResponsiveTextInputProps = {
    value: string
    placeholder: string
    disabled: boolean
    onChange: (value: string) => void
    onBlur?: (value: string) => void
}

export default function ResponsiveTextInput(
    defaultProps: ResponsiveTextInputProps,
) {
    const textAreaRef = useRef<HTMLTextAreaElement>(null)
    const { value, placeholder, onChange, onBlur, disabled } = defaultProps

    useEffect(() => {
        const textArea = textAreaRef.current
        if (textArea) {
            textArea.style.height = 'auto'
            textArea.style.height = `${textArea.scrollHeight}px`
        }
    }, [value])

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange(event.target.value)
    }

    const handleOnBlur = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (onBlur) onBlur(event.target.value)
    }

    return (
        <textarea
            ref={textAreaRef}
            value={value}
            rows={1}
            placeholder={placeholder}
            onChange={handleChange}
            onBlur={handleOnBlur}
            style={{ overflowY: 'hidden' }}
            disabled={disabled}
        />
    )
}
