import React from 'react'

import { StatusIconWrapper } from './StatusIcon.styled'

interface IconProps {
    type?: 'success' | 'warning' | 'danger'
    text?: string
}

export default function StatusIcon(defaultProps: IconProps) {
    const { type, text } = defaultProps

    return (
        <StatusIconWrapper bg={type || 'secondary'}>{text}</StatusIconWrapper>
    )
}
