import React, { useState } from 'react'
import { Alert, Dropdown, Form, FormLabel } from 'react-bootstrap'

import { Switch } from 'apps/shared/shared.styled'
import {
    LicenseStatus,
    Term,
    TimePeriod,
} from 'apps/vendor/interfaces/subscriptions'

import { DoubleSection, DropdownContainer } from '../Modals/Modals.styled'

interface LicenseTermAndBillingSectionProps {
    preselectedValues: {
        licenseStatus: LicenseStatus
        term: Term
        paymentFrequency: TimePeriod
        startDate: string
        invoiceStartDate?: string
    }
    partnerOwnedLicense: boolean
    onChangeStatus: (status: LicenseStatus) => void
    onChangeTerm: (term: Term) => void
    onChangePaymentFrequency: (frequency: TimePeriod) => void
    onChangeStartDate: (startDate: string) => void
    onChangeInvoiceStartDate: (invoiceStartDate: string) => void
    onChangeInvoicedBySharlic: (invoicedBySharlic: boolean) => void
}

export default function LicenseTermAndBillingSection(
    defaultProps: LicenseTermAndBillingSectionProps,
) {
    const {
        preselectedValues,
        partnerOwnedLicense,
        onChangeStatus,
        onChangeTerm,
        onChangePaymentFrequency,
        onChangeStartDate,
        onChangeInvoiceStartDate,
        onChangeInvoicedBySharlic,
    } = defaultProps

    const [showBillingInfo, setShowBillingInfo] = useState(!partnerOwnedLicense)

    const [invoicedBySharlic, setInvoicedBySharlic] = useState<boolean>(
        preselectedValues.licenseStatus !== LicenseStatus.MANUAL &&
            !!preselectedValues.invoiceStartDate,
    )

    const status = preselectedValues.licenseStatus || LicenseStatus.ACTIVE
    const selectedTerm = preselectedValues.term || Term.MONTH

    const selectedPaymentFrequency =
        preselectedValues.paymentFrequency || TimePeriod.MONTHLY

    const selectedStartDate = preselectedValues.startDate

    const selectedInvoiceStartDate = preselectedValues
        ? preselectedValues.invoiceStartDate
        : selectedStartDate

    const handleSetInvoicedBySharlic = () => {
        setInvoicedBySharlic(!invoicedBySharlic)
        onChangeInvoicedBySharlic(!invoicedBySharlic)

        if (
            status === LicenseStatus.ACTIVE ||
            status === LicenseStatus.MANUAL
        ) {
            onChangeStatus(
                !invoicedBySharlic
                    ? LicenseStatus.ACTIVE
                    : LicenseStatus.MANUAL,
            )
        } else if (status === LicenseStatus.INACTIVE) {
            onChangeStatus(LicenseStatus.INACTIVE)
        }

        onChangeInvoiceStartDate(!invoicedBySharlic ? selectedStartDate : '')
    }

    const handleSetTerm = (term: Term) => {
        onChangeTerm(term)

        const termIndex = Object.values(Term).indexOf(term)
        const frequency = Object.values(TimePeriod)[termIndex]

        onChangePaymentFrequency(frequency)
    }

    const renderTermDropdown = () => {
        return (
            <>
                <FormLabel>Term</FormLabel>
                <DropdownContainer>
                    <Dropdown>
                        <Dropdown.Toggle
                            variant="outline-dark"
                            id="term-toggle"
                            disabled={partnerOwnedLicense}
                        >
                            {selectedTerm}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {Object.values(Term).map((term) => (
                                <Dropdown.Item
                                    key={term}
                                    onClick={() => {
                                        handleSetTerm(term)
                                    }}
                                >
                                    {term}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </DropdownContainer>
            </>
        )
    }

    const renderPaymentFrequencyDropdown = () => {
        if (!invoicedBySharlic) return null

        return (
            <>
                <FormLabel>Payment frequency</FormLabel>
                <DropdownContainer>
                    <Dropdown>
                        <Dropdown.Toggle
                            variant="outline-dark"
                            id="payment-frequency-toggle"
                            disabled={partnerOwnedLicense}
                        >
                            {selectedPaymentFrequency}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {Object.values(TimePeriod).map((period) => (
                                <Dropdown.Item
                                    key={period}
                                    onClick={() =>
                                        onChangePaymentFrequency(period)
                                    }
                                >
                                    {period}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </DropdownContainer>
            </>
        )
    }

    const handleSetStartDate = (date: string) => {
        if (selectedInvoiceStartDate && date > selectedInvoiceStartDate) {
            onChangeInvoiceStartDate(date)
        }
        onChangeStartDate(date)
    }

    const handleSetInvoiceStartDate = (date: string) => {
        if (invoicedBySharlic && date < selectedStartDate) {
            onChangeInvoiceStartDate(selectedStartDate)
        } else {
            onChangeInvoiceStartDate(date)
        }
    }

    const renderStartDate = () => (
        <>
            <FormLabel>Start date</FormLabel>
            <Form.Control
                type="date"
                value={selectedStartDate}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleSetStartDate(e.target.value)
                }
                data-testid="start-date-input"
                disabled={partnerOwnedLicense}
            />
        </>
    )

    const renderInvoicingSwitch = (labelText: string) => {
        return (
            <Switch
                id="manual-billing-active-switch"
                checked={invoicedBySharlic}
                label={labelText}
                onChange={() => handleSetInvoicedBySharlic()}
                style={{ marginBottom: '0' }}
                disabled={partnerOwnedLicense}
            />
        )
    }

    const renderInvoiceStartDate = () => {
        return (
            <>
                {renderInvoicingSwitch('Billed by sharlic from')}
                {invoicedBySharlic && (
                    <Form.Control
                        type="date"
                        value={selectedInvoiceStartDate}
                        min={selectedStartDate}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleSetInvoiceStartDate(e.target.value)
                        }
                        disabled={partnerOwnedLicense}
                    />
                )}
            </>
        )
    }

    const renderStartDatesSection = () => {
        if (!invoicedBySharlic) {
            return (
                <>
                    {renderStartDate()}
                    {renderInvoicingSwitch('Manual Billing')}
                    <Alert
                        variant="primary"
                        dismissible
                        onClose={() => setShowBillingInfo(false)}
                        show={showBillingInfo}
                    >
                        <p>
                            Manual billing means that the license will not be
                            billed by Sharlic. <br />
                            You will have to manually invoice the customer.
                        </p>
                    </Alert>
                </>
            )
        }

        return (
            <DoubleSection>
                <div>{renderStartDate()}</div>
                <div>{renderInvoiceStartDate()}</div>
            </DoubleSection>
        )
    }

    return (
        <>
            {renderTermDropdown()}
            {renderStartDatesSection()}
            {renderPaymentFrequencyDropdown()}
        </>
    )
}
