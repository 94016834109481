import React, { useState } from 'react'

import { deleteLicense, getLicenses } from 'api/licenses'
import AccordionItem from 'apps/shared/components/AccordionItem'
import { BooleanIcon, InfoIcon } from 'apps/shared/components/Icons'
import { ConfirmModal } from 'apps/shared/components/Modals'
import { ConfirmModalBody } from 'apps/shared/components/Modals/ConfirmModal/ConfirmModal.styled'
import TextButton from 'apps/vendor/components/Buttons'
import { LicenseModal } from 'apps/vendor/components/Modals'
import { PartnerType } from 'apps/vendor/components/PartnerList'
import { LicensesTable } from 'apps/vendor/components/Tables'
import {
    CustomerType,
    LicenseType,
    MarginStructureType,
    LicenseStatus,
    Term,
    TimePeriod,
} from 'apps/vendor/interfaces/subscriptions'
import useToast from 'hooks/useToast'

import { ArticleType } from '../Articles/Articles'
import PageHeader from '../PageHeader'
import {
    AccordionWrapper,
    BadgeRow,
    NoLicensesInfoIconWrapper,
    HeaderRow,
} from '../pages.styled'

export default function Licenses(props: {
    updateLicenses: (data: LicenseType[]) => void
    licenses: LicenseType[]
    articles: ArticleType[]
    partners: PartnerType[]
    customers: CustomerType[]
    marginStructures: MarginStructureType[]
}) {
    const {
        licenses,
        articles,
        partners,
        customers,
        marginStructures,
        updateLicenses,
    } = props
    const [showLicenseModal, setShowLicenseModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const [selectedLicense, setSelectedLicense] = useState<LicenseType | null>(
        null,
    )
    const { successToast, errorToast } = useToast()

    const updateLicenseList = () => {
        getLicenses().then((response) => {
            updateLicenses(response.data)
        })
    }

    const onLicenseModalSubmit = () => {
        updateLicenseList()
        setSelectedLicense(null)
        setShowLicenseModal(false)
    }

    const handleEditLicense = (license: LicenseType) => {
        setSelectedLicense(license)
        setShowLicenseModal(!showLicenseModal)
    }

    const handleToggleDeleteModal = (license: LicenseType) => {
        setSelectedLicense(license)
        setShowDeleteModal(!showDeleteModal)
    }

    const handleDeleteLicense = () => {
        if (selectedLicense) {
            deleteLicense(selectedLicense.id)
                .then(() => {
                    updateLicenseList()
                    setSelectedLicense(null)
                    setShowDeleteModal(!showDeleteModal)
                    successToast('License successfully deleted')
                })
                .catch(() => {
                    return errorToast(
                        // eslint-disable-next-line max-len
                        'Unknown error. Please try again and contact Sharlic support if error persists',
                    )
                })
        }
    }

    const getAccordionHeaders = (license: LicenseType) => {
        return (
            <BadgeRow numberOfColumns={3}>
                <TextButton
                    onClick={() => handleEditLicense(license)}
                    text={license.name}
                />
                <span>{license.license_key}</span>
                <span className="centered">
                    <BooleanIcon
                        checked={license.status !== LicenseStatus.INACTIVE}
                    />
                </span>
            </BadgeRow>
        )
    }

    const getAccordionBody = (license: LicenseType) => {
        return (
            <LicensesTable
                license={license}
                editLicense={handleEditLicense}
                deleteLicense={handleToggleDeleteModal}
            />
        )
    }

    const renderTable = () => {
        if (!licenses) {
            return null
        }

        if (licenses.length === 0) {
            return (
                <NoLicensesInfoIconWrapper>
                    <InfoIcon />
                    <p>
                        Before adding a license, make sure you have <br />
                        {/* eslint-disable-next-line max-len */}
                        at least one <u>margin structure</u>, one <u>article</u>{' '}
                        and a <u>customer.</u>
                    </p>
                </NoLicensesInfoIconWrapper>
            )
        }

        return (
            <>
                <HeaderRow numberOfColumns={3}>
                    <span>Name</span>
                    <span>License Key</span>
                    <span className="centered">Active</span>
                </HeaderRow>
                <AccordionWrapper alwaysOpen>
                    {licenses.map((license, index) => (
                        <AccordionItem
                            key={license.license_key}
                            eventKey={index.toString()}
                            header={getAccordionHeaders(license)}
                            body={getAccordionBody(license)}
                        />
                    ))}
                </AccordionWrapper>
            </>
        )
    }

    const getTermInMonths = (term: Term) => {
        const TermInMonths = {
            MONTH: 1,
            QUARTER: 3,
            YEAR: 12,
        }

        return TermInMonths[term]
    }

    const getPaymentFrequencyInMonths = (paymentFrequency: TimePeriod) => {
        const PaymentFrequencyInMonths = {
            MONTHLY: 1,
            QUARTERLY: 3,
            ANNUALLY: 12,
        }

        return PaymentFrequencyInMonths[paymentFrequency]
    }

    const renderConfirmModalBody = () => {
        let info = <span />
        let billingInfo = <span />

        if (!selectedLicense) {
            return <span />
        }

        const termMonths = getTermInMonths(selectedLicense.term)

        const frequencyMonths = getPaymentFrequencyInMonths(
            selectedLicense.payment_frequency,
        )

        if (termMonths > frequencyMonths) {
            billingInfo = (
                <p>
                    Note that this license still has outstanding invoices before
                    end of term.
                </p>
            )
        }

        if (selectedLicense?.status === LicenseStatus.ACTIVE) {
            info = (
                <section>
                    <p>
                        Billing from Sharlic will seize <b>immediately</b>.{' '}
                        {billingInfo}
                    </p>
                </section>
            )
        }

        return (
            <ConfirmModalBody>
                <section>
                    You are about to delete the following license:
                    <p>
                        <b>{selectedLicense?.name}</b>
                    </p>
                </section>
                {info}
                <section>
                    <b>This action cannot be undone.</b>
                </section>
            </ConfirmModalBody>
        )
    }

    return (
        <div>
            <PageHeader
                onButtonClicked={() => setShowLicenseModal(!showLicenseModal)}
            />
            {showLicenseModal && (
                <LicenseModal
                    onClose={() => {
                        setShowLicenseModal(!showLicenseModal)
                        setSelectedLicense(null)
                    }}
                    onSubmit={() => onLicenseModalSubmit()}
                    articles={articles}
                    marginStructures={marginStructures}
                    partners={partners}
                    preselectedLicense={selectedLicense}
                    customers={customers}
                />
            )}
            {showDeleteModal && (
                <ConfirmModal
                    onClose={() => {
                        setShowDeleteModal(!showDeleteModal)
                        setSelectedLicense(null)
                    }}
                    onSubmit={() => handleDeleteLicense()}
                    customSubmitText="Delete"
                    body={renderConfirmModalBody()}
                    deleteButton
                />
            )}
            {renderTable()}
        </div>
    )
}
