import React from 'react'
import { Button } from 'react-bootstrap'

import { primary } from 'utils/colors'

export interface CancelSubmitFooterProps {
    onClose: () => void
    onSubmit: () => void
    canSubmit?: boolean
    customSubmitText?: string
    deleteButton?: boolean
}

export default function CancelSubmitFooter(
    defaultProps: CancelSubmitFooterProps,
) {
    const {
        onClose,
        onSubmit,
        canSubmit = true,
        customSubmitText,
        deleteButton,
    } = defaultProps

    return (
        <>
            <Button variant="light" onClick={onClose}>
                Cancel
            </Button>
            <Button
                onClick={onSubmit}
                disabled={!canSubmit}
                style={
                    deleteButton
                        ? {
                              backgroundColor: primary.red,
                              borderColor: primary.darkRed,
                          }
                        : undefined
                }
            >
                {customSubmitText || 'Save'}
            </Button>
        </>
    )
}
