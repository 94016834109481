import { Dropdown } from 'react-bootstrap'

import styled from 'styled-components'
import { primary, secondary } from 'utils/colors'

export const KebabWrapper = styled(Dropdown)`
    background: none;

    > .dropdown-toggle {
        background: none;
        padding: 0;
        margin: 0;
        color: ${primary.blue};
        border: none;
        font-size: 0.9em;

        ::after {
            display: none;
        }

        :active,
        :focus {
            background: none;
            color: ${primary.lightGray};
        }
    }

    > .dropdown-menu {
        background-color: ${secondary.almostWhiteGray};
        box-shadow: 0 0 5px 0 #00000033;

        > .dropdown-item {
            color: ${primary.darkGray};
            padding: 0.5em 1em;

            > .edit {
                color: ${primary.blue};
            }

            > .delete {
                color: ${primary.red};
            }

            :active {
                background-color: ${secondary.lightGray};
            }
        }
    }
`
