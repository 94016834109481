import React, { useState } from 'react'

import { getCustomers } from 'api/customers'
import { getInvoices } from 'api/invoices'
import AccordionItem from 'apps/shared/components/AccordionItem/AccordionItem'
import { EditButton } from 'apps/shared/components/Buttons'
import { EmptyListIcon } from 'apps/shared/components/Icons'
import TextButton from 'apps/vendor/components/Buttons'
import CustomerModal from 'apps/vendor/components/Modals/CustomerModal'
import { CustomerType } from 'apps/vendor/interfaces/subscriptions'
import { formatDate } from 'apps/vendor/utils'
import useUser from 'hooks/useUser'

import PageHeader from '../PageHeader'
import {
    AccordionBody,
    AccordionWrapper,
    BadgeRow,
    HeaderRow,
} from '../pages.styled'

export default function CustomersPage(props: {
    updateCustomers: (data: CustomerType[]) => void
    customers: CustomerType[]
}) {
    const { customers, updateCustomers } = props
    const [showCustomerModal, setShowCustomerModal] = useState(false)

    const [selectedCustomer, setSelectedCustomer] =
        useState<CustomerType | null>(null)

    const { user } = useUser()

    const updateCustomersList = () => {
        getCustomers().then((resp) => {
            updateCustomers(resp.data)
        })
    }

    const handleShowModal = () => {
        setShowCustomerModal(!showCustomerModal)
    }

    const handleOpenEditModal = (customer: CustomerType) => {
        setSelectedCustomer(customer)

        getInvoices(customer.id).then((res: any) => {
            setSelectedCustomer({
                ...customer,
                invoices: res.data.reverse(),
            })
            handleShowModal()
        })
    }

    const getCustomerBody = (customer: CustomerType) => {
        const dateString = formatDate(
            new Date(customer.created_at),
            user?.locale,
        )

        return (
            <AccordionBody>
                <div>
                    <p>Created</p>
                    <p>{dateString}</p>
                </div>
                <EditButton
                    onClick={() => {
                        handleOpenEditModal(customer)
                    }}
                />
            </AccordionBody>
        )
    }

    const onCustomerModalSubmit = () => {
        updateCustomersList()
        setSelectedCustomer(null)
        setShowCustomerModal(false)
    }

    const renderTable = () => {
        if (!customers) {
            return null
        }

        if (customers.length === 0) {
            return <EmptyListIcon />
        }

        return (
            <>
                <HeaderRow numberOfColumns={3}>
                    <span>Name</span>
                    <span>Reference</span>
                    <span>Email</span>
                </HeaderRow>
                <AccordionWrapper alwaysOpen>
                    {customers.map((customer, index) => {
                        return (
                            <AccordionItem
                                key={customer.id}
                                eventKey={index.toString()}
                                header={
                                    <BadgeRow numberOfColumns={3}>
                                        <TextButton
                                            onClick={() =>
                                                handleOpenEditModal(customer)
                                            }
                                            text={customer.name}
                                        />
                                        <span>{customer.reference || '-'}</span>
                                        <a
                                            href={`mailto:$
                                                {customer.contact_email}`}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            {customer.contact_email}
                                        </a>
                                    </BadgeRow>
                                }
                                body={getCustomerBody(customer)}
                            />
                        )
                    })}
                </AccordionWrapper>
            </>
        )
    }

    return (
        <div>
            <PageHeader onButtonClicked={() => handleShowModal()} />
            {showCustomerModal && (
                <CustomerModal
                    onClose={() => {
                        handleShowModal()
                        setSelectedCustomer(null)
                    }}
                    onSubmit={() => onCustomerModalSubmit()}
                    preselectedCustomer={selectedCustomer}
                    customers={customers}
                />
            )}
            {renderTable()}
        </div>
    )
}
