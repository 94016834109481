import { Form } from 'react-bootstrap'

import styled from 'styled-components'
import { primary, secondary } from 'utils/colors'

export const DoubleSection = styled.section`
    > div > * {
        margin-bottom: 0.5rem;
    }

    @media screen and (min-width: 991px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        margin-bottom: 0;
    }
`

export const ModalWrapper = styled.div`
    > * {
        margin-bottom: 0.5rem;
    }
`

export const DropdownContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0;
    margin-bottom: 0.5rem;

    .dropdown-menu {
        width: 100%;
        max-height: 200px;
        padding-top: 0;
        overflow-y: scroll;
    }
    .dropdown-toggle:hover {
        background-color: ${secondary.lightGray};
        color: black;
    }
    .dropdown-toggle.show {
        background-color: ${secondary.lightGray};
        color: black;
    }
    .dropdown-toggle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: left;
        border: 1px solid ${secondary.lightGray};
        width: 100%;
    }
`

export const Checkbox = styled(Form.Check)`
    > label {
        font-size: 0.9rem;
    }
    * {
        cursor: pointer;
    }
`

export const RemoveListItemButton = styled.i`
    color: ${primary.blue};
`

export const MissingAssetText = styled.p`
    color: ${primary.red};
    font-style: italic;
`
