import React, { useEffect, useRef, useState } from 'react'
import { Form, Tab } from 'react-bootstrap'

import { editCustomer, addCustomer } from 'api/customers'
import { ItemSelectorDropdown } from 'apps/shared/components/Dropdown'
import {
    EmailInput,
    PhoneInputField,
} from 'apps/shared/components/FormInputFields'
import { Modal } from 'apps/shared/components/Modals'
import { CancelSubmitFooter } from 'apps/shared/components/Modals/Footers'
import { ErrorMessage, FormLabel } from 'apps/shared/shared.styled'
import {
    CountryCode,
    Currency,
    CustomerType,
    Language,
} from 'apps/vendor/interfaces/subscriptions'
import { TabWrapper } from 'apps/vendor/pages/Administration/Administration.styled'
import useToast from 'hooks/useToast'
import isEmail from 'validator/lib/isEmail'

import { InvoicesTable } from '../../Tables'
import { Checkbox, DoubleSection, ModalWrapper } from '../Modals.styled'
import { BillingSection, WarningMessage } from './CustomerModal.styled'

interface CustomerModalProps {
    onClose: () => void
    onSubmit: () => void
    preselectedCustomer?: CustomerType | null
    customers?: CustomerType[]
}

export default function CustomerModal(defaultProps: CustomerModalProps) {
    const { onClose, onSubmit, preselectedCustomer, customers } = defaultProps
    const [name, setName] = useState(preselectedCustomer?.name || '')

    const [customerId, setCustomerId] = useState<string | null>(
        preselectedCustomer?.external_customer_id || null,
    )
    const [idError, setIdError] = useState('')

    const [reference, setReference] = useState(
        preselectedCustomer?.reference || '',
    )
    const [email, setEmail] = useState(preselectedCustomer?.contact_email || '')
    const [emailError, setEmailError] = useState('')
    const [emailWarning, setEmailWarning] = useState('')

    const [description, setDescription] = useState(
        preselectedCustomer?.description || '',
    )

    const [address1, setAddress1] = useState(
        preselectedCustomer?.address_line_1 || '',
    )

    const [address2, setAddress2] = useState(
        preselectedCustomer?.address_line_2 || '',
    )
    const [city, setCity] = useState(preselectedCustomer?.city || '')
    const [state, setState] = useState(preselectedCustomer?.state || '')

    const [countryCode, setCountryCode] = useState(
        preselectedCustomer?.country || '',
    )
    const [zipCode, setZipCode] = useState(preselectedCustomer?.zip_code || '')

    const [phoneNumber, setPhoneNumber] = useState(
        preselectedCustomer?.phone_number || '',
    )
    const [phoneError, setPhoneError] = useState('')

    const [language, setLanguage] = useState(
        preselectedCustomer?.language || '',
    )

    const [currency, setCurrency] = useState(
        preselectedCustomer?.currency || '',
    )

    const [taxable, setTaxable] = useState<boolean>(
        preselectedCustomer?.taxable || false,
    )
    const [canSubmit, setCanSubmit] = useState(true)
    const { errorToast, successToast } = useToast()
    const inputRef = useRef<HTMLInputElement>(null)
    const [activeTab, setActiveTab] = useState('customer')

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])

    useEffect(() => {
        let isEdited = true

        const hasErrors =
            emailError !== '' || phoneError !== '' || idError !== ''

        if (preselectedCustomer) {
            isEdited =
                preselectedCustomer.name !== name ||
                preselectedCustomer.external_customer_id !== customerId ||
                preselectedCustomer.reference !== reference ||
                preselectedCustomer.contact_email !== email ||
                preselectedCustomer.description !== description ||
                preselectedCustomer.country !== (countryCode || '') ||
                preselectedCustomer.address_line_1 !== address1 ||
                preselectedCustomer.address_line_2 !== address2 ||
                preselectedCustomer.zip_code !== zipCode ||
                preselectedCustomer.city !== city ||
                preselectedCustomer.state !== state ||
                preselectedCustomer.phone_number !== phoneNumber ||
                preselectedCustomer.language !== language ||
                preselectedCustomer.currency !== currency ||
                preselectedCustomer.taxable !== taxable
        }

        const requiredFieldsSet =
            name !== '' &&
            email !== '' &&
            countryCode !== '' &&
            address1 !== '' &&
            zipCode !== '' &&
            city !== '' &&
            (countryCode === 'US' ? state !== '' : true) &&
            language !== '' &&
            currency !== ''
        const isValidEmail = isEmail(email)

        setCanSubmit(
            isEdited &&
                requiredFieldsSet &&
                isValidEmail &&
                !phoneError &&
                !hasErrors,
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        name,
        customerId,
        idError,
        reference,
        email,
        description,
        countryCode,
        address1,
        address2,
        zipCode,
        city,
        state,
        phoneNumber,
        phoneError,
        language,
        currency,
        emailError,
        taxable,
    ])

    const handleSubmit = () => {
        if (!canSubmit) {
            return
        }

        const customerData = {
            name,
            external_customer_id: customerId,
            reference,
            contact_email: email,
            description,
            country: countryCode as CountryCode,
            address_line_1: address1,
            address_line_2: address2,
            zip_code: zipCode,
            city,
            state,
            phone_number: phoneNumber,
            language: language as Language,
            currency: currency as Currency,
            taxable,
        }

        const apiCall = preselectedCustomer
            ? editCustomer(customerData, preselectedCustomer.id)
            : addCustomer(customerData)

        apiCall
            .then(() => {
                onSubmit()

                successToast(
                    preselectedCustomer
                        ? 'Customer updated successfully.'
                        : 'Customer created successfully.',
                )
            })
            .catch((err) => {
                if (err.response.data.contact_email) {
                    setEmailError('Invalid email.')
                }

                if (err.response.status === 409) {
                    return errorToast(`Customer already exists.`)
                }

                if (err.response.status !== 500) {
                    if (
                        err.response.data.non_field_errors[0].includes(
                            'external_customer_id',
                        )
                    ) {
                        return setIdError(
                            'Customer with this ID already exists.',
                        )
                    }

                    return errorToast(
                        preselectedCustomer
                            ? 'Failed to update customer, please try again.'
                            : 'Failed to create customer, please try again.',
                    )
                }

                return errorToast(
                    // eslint-disable-next-line max-len
                    'Unknown error. Please try again and contact Sharlic support if error persists.',
                )
            })
    }

    const checkIfEmailExists = (newEmail: string) => {
        if (
            customers &&
            customers.some((customer) => customer.contact_email === newEmail)
        ) {
            setEmailWarning(
                `You already have a customer with this invoice email
                registered. Please verify email is correct before proceeding.`,
            )

            return
        }

        setEmailWarning('')
    }

    const handleSetEmail = (value: string) => {
        setEmail(value)
        checkIfEmailExists(value)
        setEmailError('')
    }

    const generateTextInputField = (
        value: string,
        action: any,
        label?: string,
        optional?: boolean,
    ) => {
        return (
            <>
                {label && (
                    <FormLabel>
                        {label}
                        {optional ? <span> - Optional</span> : null}
                    </FormLabel>
                )}
                <Form.Control
                    {...{
                        value,
                        type: 'text',
                        onChange: (e: any) => action(e.target.value),
                        onBlur: (e: any) => action(e.target.value.trim()),
                    }}
                />
            </>
        )
    }

    const renderNameField = () => {
        return (
            <>
                <FormLabel>Name</FormLabel>
                <Form.Control
                    {...{
                        ref: inputRef,
                        value: name,
                        type: 'text',
                        onChange: (e: any) => setName(e.target.value),
                        onBlur: (e: any) => setName(e.target.value.trim()),
                    }}
                />
            </>
        )
    }

    const handleSetCustomerID = (value: string) => {
        setIdError('')
        if (!value) {
            setCustomerId(null)

            return
        }

        setCustomerId(value)
    }

    const renderExternalCustomerIdField = () => {
        return (
            <>
                <FormLabel>
                    Customer ID<span> - Optional</span>
                </FormLabel>
                <Form.Control
                    {...{
                        value: customerId?.toString() || '',
                        type: 'text',
                        onChange: (e: any) =>
                            handleSetCustomerID(e.target.value),
                        onBlur: (e: any) =>
                            handleSetCustomerID(e.target.value.trim()),
                    }}
                />
                {idError && <ErrorMessage>{idError}</ErrorMessage>}
            </>
        )
    }

    const renderReferenceField = () => {
        return (
            <>
                <FormLabel>Reference</FormLabel>
                <Form.Control
                    {...{
                        value: reference,
                        type: 'text',
                        onChange: (e: any) => setReference(e.target.value),
                        onBlur: (e: any) => setReference(e.target.value.trim()),
                    }}
                />
            </>
        )
    }

    const renderEmailField = () => {
        return (
            <>
                <FormLabel>Email</FormLabel>
                <EmailInput
                    {...{
                        value: email,
                        onChange: (value: string) => handleSetEmail(value),
                    }}
                />
                {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
                {emailWarning && (
                    <WarningMessage>{emailWarning}</WarningMessage>
                )}
            </>
        )
    }

    const renderDescriptionField = () => {
        return (
            <>
                {generateTextInputField(
                    description,
                    setDescription,
                    'Description',
                    true,
                )}
            </>
        )
    }

    const handleSetCountry = (code: CountryCode) => {
        setCountryCode(code)
        setState('')
        setPhoneError('')

        setPhoneNumber('')
    }

    const renderCountryDropdown = () => {
        const countries = [
            { code: 'SE', name: 'Sweden' },
            { code: 'US', name: 'United States' },
        ]

        return (
            <ItemSelectorDropdown
                preselectedValue={countryCode || ''}
                items={countries}
                onClick={handleSetCountry}
                placeholder="Country"
            />
        )
    }

    const renderLanguageDropdown = () => {
        const languages = [
            { code: 'sv-SE', name: 'Swedish' },
            { code: 'en-US', name: 'English' },
        ]

        return (
            <>
                <FormLabel>Language</FormLabel>
                <ItemSelectorDropdown
                    preselectedValue={language || ''}
                    items={languages}
                    onClick={setLanguage}
                />
            </>
        )
    }

    const renderCurrencyDropDown = () => {
        const currencies = [
            { code: 'SEK', name: 'Swedish Krona' },
            { code: 'USD', name: 'United States Dollar' },
        ]

        return (
            <>
                <FormLabel>Currency</FormLabel>
                <ItemSelectorDropdown
                    preselectedValue={currency || ''}
                    items={currencies}
                    onClick={setCurrency}
                    disabled={!!preselectedCustomer}
                />
            </>
        )
    }

    const renderPhoneSection = () => {
        return (
            <>
                <FormLabel>
                    Phone number
                    <span> - Optional</span>
                </FormLabel>
                <PhoneInputField
                    {...{
                        phoneNumber: phoneNumber || '',
                        onChange: (phoneInput, errorMessage) => {
                            setPhoneNumber(phoneInput)
                            setPhoneError(errorMessage)
                        },
                        selectedCountry: countryCode as CountryCode,
                    }}
                />
                {phoneError && <ErrorMessage>{phoneError}</ErrorMessage>}
            </>
        )
    }

    const renderBillingInfo = () => {
        if (!countryCode) {
            return null
        }

        return (
            <BillingSection>
                {generateTextInputField(
                    address1,
                    setAddress1,
                    'Address field 1',
                )}
                {generateTextInputField(
                    address2,
                    setAddress2,
                    'Address field 2',
                    true,
                )}
                <DoubleSection>
                    <div>
                        {generateTextInputField(
                            zipCode,
                            setZipCode,
                            'Zip code / Postal code',
                        )}
                    </div>
                    <div>{generateTextInputField(city, setCity, 'City')}</div>
                </DoubleSection>
                {countryCode === 'US' &&
                    generateTextInputField(state, setState, 'State')}
                {renderPhoneSection()}
            </BillingSection>
        )
    }

    const renderBillingInfoSection = () => {
        return (
            <>
                <FormLabel>Billing details</FormLabel>
                {renderCountryDropdown()}
                {renderBillingInfo()}
            </>
        )
    }

    return (
        <Modal
            size="xl"
            onClose={onClose}
            title={
                preselectedCustomer ? (
                    <TabWrapper
                        className="inside-modal"
                        activeKey={activeTab}
                        onSelect={(k) => setActiveTab(k || 'customer')}
                    >
                        <Tab
                            title="Edit"
                            eventKey="customer"
                            id="0"
                            className="active"
                        />
                        {preselectedCustomer.invoices &&
                            preselectedCustomer.invoices.length > 0 && (
                                <Tab
                                    title="Invoices"
                                    eventKey="invoices"
                                    id="1"
                                />
                            )}
                    </TabWrapper>
                ) : (
                    'New'
                )
            }
            body={
                <ModalWrapper>
                    {activeTab === 'customer' && (
                        <>
                            <DoubleSection>
                                <div>
                                    {renderNameField()}
                                    {renderReferenceField()}
                                </div>
                                <div>
                                    {renderExternalCustomerIdField()}
                                    {renderEmailField()}
                                </div>
                            </DoubleSection>
                            {renderDescriptionField()}
                            {renderBillingInfoSection()}
                            <DoubleSection>
                                <div>{renderLanguageDropdown()}</div>
                                <div>{renderCurrencyDropDown()}</div>
                            </DoubleSection>
                            <Checkbox
                                {...{
                                    id: 'taxable',
                                    name: 'taxable',
                                    label: 'Taxable',
                                    defaultChecked: taxable,
                                    onChange: () => setTaxable(!taxable),
                                }}
                            />
                        </>
                    )}
                    {activeTab === 'invoices' && preselectedCustomer && (
                        <InvoicesTable customer={preselectedCustomer} />
                    )}
                </ModalWrapper>
            }
            footer={
                activeTab === 'customer' ? (
                    <CancelSubmitFooter
                        onClose={onClose}
                        onSubmit={handleSubmit}
                        canSubmit={canSubmit}
                    />
                ) : null
            }
        />
    )
}
